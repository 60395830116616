import gsap from 'gsap';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas-pro';
import { Bar, Radar } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, RadialLinearScale, LineElement, PointElement, CategoryScale, LinearScale, BarElement, Filler, Tooltip } from 'chart.js';
import { useEffect, useRef, useState } from "react";
import TaskCharacterization from '../../Components/TaskCharacterization/TaskCharacterization';
import "./Results.css";

export default function Results({
    personPosition, companyCountry, industrialSector, companySize, numberOfSites, energyConsumption, 
    assessmentLevel, pastAudits, mandatoryAudits, energyManagementSystemBool, energyManagementSystem, 
    organizationalContextScore, leadershipScore, planningScore, supportScore, operationsScore, 
    performanceEvaluationScore, improvementScore, 
    taskScore1, taskScore2, taskScore3, taskScore4, taskScore5, taskScore6, taskScore7, taskScore8, 
    taskScore9, taskScore10, taskScore11, taskScore12, taskScore13, taskScore14, taskScore15, 
    taskScore16, taskScore17, taskScore18, taskScore19, taskScore20, taskScore21, taskScore22, 
    taskScore23, taskScore24, taskScore25
    }) {

    ChartJS.register(LineController, LineElement, CategoryScale, LinearScale, Filler, BarElement, RadialLinearScale, PointElement, Tooltip);
    ChartJS.defaults.font.size = window.innerWidth > 769 ? 15 : 12
    ChartJS.defaults.font.family = 'DM Sans, sans-serif'

    const industrialSectorOptions = [
        {id: 0, text: "Machinery"},
        {id: 1, text: "Non metallic mineral"},
        {id: 2, text: "Chemicals"},
        {id: 3, text: "Pharmaceuticals"},
        {id: 4, text: "Metal"},
        {id: 5, text: "Plastics"},
        {id: 6, text: "Pulp & Paper"},
        {id: 7, text: "Automotive"},
        {id: 8, text: "Food"},
        {id: 9, text: "Oil & Gass Processing"},
        {id: 10, text: "Ceramic"},
        {id: 11, text: "Textile Production"},
        {id: 12, text: "Cement & Building Materials"},
        {id: 13, text: "Others"},
    ]

    const companySizeOptions = [
        {id: 0, text: "Micro"},
        {id: 1, text: "Small"},
        {id: 2, text: "Medium"},
        {id: 3, text: "Large"},
    ]

    const assessementLevelOptions = [
        {id: 0, text: 'HQ'},
        {id: 1, text: 'Site'},
        {id: 2, text: 'Entire Company'}
    ]

    const mandatoryAuditsOptions = [
        {id: 0, text: 'Mandatory'},
        {id: 1, text: 'Optional'},
    ]

    const sectionCharacterizationEls = useRef([])

    const colorScale = ['#fe9f9f', '#e39ec9', '#bfa4d7', '#9ea8cc', '#89a8b5']
    const sectionCharacteriazations = ['learner', 'beginner', 'organized', 'achiever', 'world class']
    const taskCharacteriazations = ['informal', 'documented', 'integrated', 'strategic', 'optimized']
    const taskCharacterizationsTexts = [
        'Energy management tasks are performed sporadically without formal procedures. There is minimal documentation, inconsistent practices, and unclear responsibilities. ISO 50001 implementation is low due to the lack of a structured approach.',
        'Basic energy management procedures are documented. This level provides a foundation for ISO 50001 but lacks comprehensive integration.',
        'Energy management tasks are integrated into overall business processes. The organization is ready for ISO 50001 as energy management practices are part of the organization’s standard practices and processes.',
        'Energy management is strategically aligned with long-term organizational goals. The maturity for ISO50001 is enhanced as energy management practices are embedded into the strategic framework.',
        'Energy management tasks are optimized using advanced technologies and continuous improvement practices. The organization is fully prepared for ISO 50001, demonstrating industry-leading energy management excellence.',
    ]

    const setSectionCharacterization = (score) => {
        if(score < 20) {
            return sectionCharacteriazations[0]
        } else if(score < 40) {
            return sectionCharacteriazations[1]
        } else if(score < 60) {
            return sectionCharacteriazations[2]
        } else if(score < 80) {
            return sectionCharacteriazations[3]
        } else {
            return sectionCharacteriazations[4]
        }
    }

    const setBarColor= (score) => {
        if(score < 20) {
            return colorScale[0]
        } else if(score < 40) {
            return colorScale[1]
        } else if(score < 60) {
            return colorScale[2]
        } else if(score < 80) {
            return colorScale[3]
        } else {
            return colorScale[4]
        }
    }

    useEffect(() => {
        document.title = 'Maturity Model | Results';
        for(let sectionCharacterizationEl of sectionCharacterizationEls.current) {
            switch(sectionCharacterizationEl.children[1].textContent) {
                case sectionCharacteriazations[0]:
                    gsap.set(sectionCharacterizationEl, {backgroundColor: colorScale[0]})
                    break 
                case sectionCharacteriazations[1]:
                    gsap.set(sectionCharacterizationEl, {backgroundColor: colorScale[1]})
                    break 
                case sectionCharacteriazations[2]:
                    gsap.set(sectionCharacterizationEl, {backgroundColor: colorScale[2]})
                    break 
                case sectionCharacteriazations[3]:
                    gsap.set(sectionCharacterizationEl, {backgroundColor: colorScale[3]})
                    break 
                case sectionCharacteriazations[4]:
                    gsap.set(sectionCharacterizationEl, {backgroundColor: colorScale[4]})
                    break 
                default:
                    break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveToPdf = () => {
        html2canvas(document.body).then(function(canvas) {
            var imgData = canvas.toDataURL("image/jpeg", 2);
            var pdf = new jsPDF({
                orientation: 'portrait', 
                unit: 'px', 
                format:[window.innerWidth, document.body.offsetHeight]
            });
            pdf.addImage(imgData, 'JPEG', 0, 0, window.innerWidth, document.body.offsetHeight);
            pdf.save("maturity-assessment.pdf");
        });
    }

    const getDate = () => {
        let today = new Date()
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${date}/${month}/${year}`;
    }

    const [currentDate, setCurrentDate] = useState(getDate());

    return (
        <div className="results-wrapper">
            <div className="info-wrapper">
                <div className='info-wrapper-row'>
                <h2 className="info-heading">General Information</h2>
                <p className='date-stamp'>{currentDate}</p>
                </div>
                <div className="general-info-list">
                    <div className="info">
                        <div className="info-label">Country</div>
                        <div className="info-value">{companyCountry}</div>
                    </div>
                    <div className="info">
                        <div className="info-label">Industrial Sector</div>
                        <div className="info-value">{industrialSectorOptions[industrialSector].text}</div>
                    </div>
                    <div className="info">
                        <div className="info-label">Company Size</div>
                        <div className="info-value">{companySizeOptions[companySize].text}</div>
                    </div>
                    <div className="info">
                        <div className="info-label">EMM Assessment Level</div>
                        <div className="info-value">{assessementLevelOptions[assessmentLevel].text}</div>
                    </div>
                    <div className="info">
                        <div className="info-label">Audits Obligation</div>
                        <div className="info-value">{mandatoryAuditsOptions[mandatoryAudits].text}</div>
                    </div>
                    <div className="info">
                        <div className="info-label">Existing EMS</div>
                        <div className="info-value">{energyManagementSystemBool === '0' ? energyManagementSystem : 'None'}</div>
                    </div>
                </div>
            </div>
            <div className="info-wrapper sections-score-wrapper">
                <h2 className="info-heading">Sections Scores</h2>
                <div className='section-score-appendix-list'>
                    <div className='section-score-appendix'>
                        <p className='section-score-appendix-title section-score--1'>{sectionCharacteriazations[0]}</p>
                        <p className='section-score-appendix-description'>At the Learner level, the organization is in the initial stages of understanding energy management concepts and the importance of energy efficiency. Energy management practices are informal and sporadic, with little to no structured approach in place. Employees may have limited awareness of energy management issues, and data on energy consumption is rarely collected or analyzed. The organization has not yet established clear energy performance goals, and any energy-saving efforts are typically reactive and uncoordinated.</p>
                    </div>
                    <div className='section-score-appendix'>
                        <p className='section-score-appendix-title section-score--2'>{sectionCharacteriazations[1]}</p>
                        <p className='section-score-appendix-description'>At the Beginner level, the organization has started to recognize the value of energy management and is taking steps to develop a more systematic approach. Basic policies and procedures are being established, and a designated person or team is responsible for overseeing energy management activities. Regular collection and periodic review of energy consumption data begin to take shape. Initial energy-saving initiatives are implemented, but they may still lack consistency and full integration into broader business processes. Goals for energy performance are set, though they may be relatively modest.</p>
                    </div>
                    <div className='section-score-appendix'>
                        <p className='section-score-appendix-title section-score--3'>{sectionCharacteriazations[2]}</p>
                        <p className='section-score-appendix-description'>At the Organized level, the organization has developed a structured and systematic approach to energy management, with well-defined policies and procedures in place. Energy management is integrated into daily operations, and there is a clear assignment of roles and responsibilities. Energy consumption data is consistently monitored, analyzed, and reported, providing valuable insights for decision-making. A range of energy-saving initiatives is implemented consistently, and ambitious energy performance targets are set, monitored, and reviewed regularly. The organization places a strong emphasis on energy management and continuous improvement.</p>
                    </div>
                    <div className='section-score-appendix'>
                        <p className='section-score-appendix-title section-score--4'>{sectionCharacteriazations[3]}</p>
                        <p className='section-score-appendix-description'>At the Achiever level, the organization demonstrates a high level of competence in energy management, which is deeply embedded in its operational and strategic processes. Advanced energy monitoring and control systems are used to enable real-time data analysis and optimization. Continuous improvement is a central focus, with regular assessments and updates to energy-saving measures. The organization consistently meets or exceeds its energy performance targets, showcasing a strong commitment to sustainability and efficiency. Best practices are shared across the organization, fostering a culture of energy awareness and responsibility.</p>
                    </div>
                    <div className='section-score-appendix'>
                        <p className='section-score-appendix-title section-score--5'>{sectionCharacteriazations[4]}</p>
                        <p className='section-score-appendix-description'>At the World Class level, the organization is recognized as an industry leader in energy management, setting benchmarks for excellence. Energy management practices are highly advanced, innovative, and seamlessly integrated into all aspects of the organization's operations. Cutting-edge technologies and methodologies are employed to maximize energy efficiency and minimize waste. The organization consistently achieves outstanding energy performance results and is celebrated for its sustainability efforts. Continuous improvement and innovation are ingrained in the organizational culture, making energy management a core value and a source of competitive advantage.</p>
                    </div>
                </div>
                <div className="sections-scores-list">
                    <div className="section-score-wrapper">
                        <div className="section-score-bubble" ref={(element) => sectionCharacterizationEls.current[0] = element}>
                            <p className='section-score'>{organizationalContextScore}/100</p>
                            <p className="section-characterization">{setSectionCharacterization(organizationalContextScore)}</p>
                        </div>
                        <p className="section-label">Organizational Context</p>
                    </div>
                    <div className="section-score-wrapper">
                        <div className="section-score-bubble" ref={(element) => sectionCharacterizationEls.current[1] = element}>
                            <p className='section-score'>{leadershipScore}/100</p>
                            <p className="section-characterization">{setSectionCharacterization(leadershipScore)}</p>
                        </div>
                        <p className="section-label">Leadership</p>
                    </div>
                    <div className="section-score-wrapper">
                        <div className="section-score-bubble" ref={(element) => sectionCharacterizationEls.current[2] = element}>
                            <p className='section-score'>{planningScore}/100</p>
                            <p className="section-characterization">{setSectionCharacterization(planningScore)}</p>
                        </div>
                        <p className="section-label">Planning</p>
                        
                    </div>
                    <div className="section-score-wrapper">
                        <div className="section-score-bubble" ref={(element) => sectionCharacterizationEls.current[3] = element}>
                            <p className='section-score'>{supportScore}/100</p>
                            <p className="section-characterization">{setSectionCharacterization(supportScore)}</p>
                        </div>
                        <p className="section-label">Support</p>
                    </div>
                    <div className="section-score-wrapper">
                        <div className="section-score-bubble" ref={(element) => sectionCharacterizationEls.current[4] = element}>
                            <p className='section-score'>{operationsScore}/100</p>
                            <p className="section-characterization">{setSectionCharacterization(operationsScore)}</p>
                        </div>
                        <p className="section-label">Operations</p>
                    </div>
                    <div className="section-score-wrapper">
                        <div className="section-score-bubble" ref={(element) => sectionCharacterizationEls.current[5] = element}>
                            <p className='section-score'>{performanceEvaluationScore}/100</p>
                            <p className="section-characterization">{setSectionCharacterization(performanceEvaluationScore)}</p>
                        </div>
                        <p className="section-label">Performance Evaluation</p>
                    </div>
                    <div className="section-score-wrapper">
                        <div className="section-score-bubble" ref={(element) => sectionCharacterizationEls.current[6] = element}>
                            <p className='section-score'>{improvementScore}/100</p>
                            <p className="section-characterization">{setSectionCharacterization(improvementScore)}</p>
                        </div>
                        <p className="section-label">Improvement</p>
                    </div>
                </div>
            </div>
            <div className="info-row">
                <div className="info-wrapper radar-wrapper">
                    <h2 className="info-heading radar-info-heading">Sections Scores Radar Graph</h2>
                    <Radar 
                        data={{
                            labels: ['Organizational Context', 'Leadership', 'Planning', 'Support', 'Operations', 'Performance Evaluation', 'Improvement'],
                            datasets: [{
                                data: [organizationalContextScore, leadershipScore, planningScore, supportScore, operationsScore, performanceEvaluationScore, improvementScore],
                                fill: true,
                                backgroundColor: 'rgba(137,168,181, 0.4)',
                                borderColor: '#89A8B5',
                                pointBackgroundColor: '#89A8B5',
                                pointBorderColor: 'rgba(137,168,181, 0.4)',
                                borderWidth: 2,
                            }]
                        }} 
                    />
                    <p className='faded'>(Maximum Score: 100)</p>
                </div>
                <div className="info-wrapper bar-wrapper">
                    <h2 className="info-heading">Tasks Scores Bar Graph</h2>
                    <Bar 
                        // type='bar'
                        data={{
                            labels: ['Organizational Context', 'Leadership', 'Planning', 'Support', 'Operations', 'Performance Evaluation', 'Improvement'],
                            datasets: [{
                                data: [organizationalContextScore, leadershipScore, planningScore, supportScore, operationsScore, performanceEvaluationScore, improvementScore],
                                borderWidth: 2,
                                borderColor: [setBarColor(organizationalContextScore), setBarColor(leadershipScore), setBarColor(planningScore), setBarColor(supportScore), setBarColor(operationsScore), setBarColor(performanceEvaluationScore), setBarColor(improvementScore)],
                                backgroundColor: [setBarColor(organizationalContextScore), setBarColor(leadershipScore), setBarColor(planningScore), setBarColor(supportScore), setBarColor(operationsScore), setBarColor(performanceEvaluationScore), setBarColor(improvementScore)], 
                            }]
                        }} 
                        options={{
                            indexAxis: 'y',
                            interaction: {
                                intersect: false,
                                mode: 'index',
                            },
                            plugins: {
                                tooltip: {
                                    backgroundColor: '#1A202C',
                                    callbacks: {
                                        label: function(tooltipItem) {
                                            return tooltipItem.formattedValue;
                                        }
                                    }
                                }
                            }
                            
                        }}
                    />
                    <p className='faded faded--2'>(Maximum Score: 100)</p>
                </div>
            </div>
            <div className="info-wrapper">
                <h2 className="info-heading">Tasks Scores</h2>
                <div className='task-score-appendix-list'>
                    <div className='task-score-appendix'>
                        <p className='task-score-appendix-title task-score--1'>{taskCharacteriazations[0]}</p>
                        <p className='task-score-appendix-description'>{taskCharacterizationsTexts[0]}</p>
                    </div>
                    <div className='task-score-appendix'>    
                        <p className='task-score-appendix-title task-score--2'>{taskCharacteriazations[1]}</p>
                        <p className='task-score-appendix-description'>{taskCharacterizationsTexts[1]}</p>
                    </div>
                    <div className='task-score-appendix'>
                        <p className='task-score-appendix-title task-score--3'>{taskCharacteriazations[2]}</p>
                        <p className='task-score-appendix-description'>{taskCharacterizationsTexts[2]}</p>
                    </div>
                    <div className='task-score-appendix'>
                        <p className='task-score-appendix-title task-score--4'>{taskCharacteriazations[3]}</p>
                        <p className='task-score-appendix-description'>{taskCharacterizationsTexts[3]}</p>
                    </div>
                    <div className='task-score-appendix'>
                        <p className='task-score-appendix-title task-score--5'>{taskCharacteriazations[4]}</p>
                        <p className='task-score-appendix-description'>{taskCharacterizationsTexts[4]}</p>
                    </div>
                </div>
                <div className="tasks-list">
                    <div className="task-list-labels">
                        <p className="task-label">Name</p>
                        <p className="task-label">Score</p>
                        <p className="task-label" title="Characterization">Characterization</p>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 1: Context of the organization</p>
                        <p className="task-score">{taskScore1}/100</p>
                        <TaskCharacterization score={taskScore1}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 2: Needs and Expectations of Interested Parties</p>
                        <p className="task-score">{taskScore2}/100</p>
                        <TaskCharacterization score={taskScore2} ></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 3: Scope and Boundaries of the EnMS</p>
                        <p className="task-score">{taskScore3}/100</p>
                        <TaskCharacterization score={taskScore3} ></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 4:  Top Management and Leadership Commitment</p>
                        <p className="task-score">{taskScore4}/100</p>
                        <TaskCharacterization score={taskScore4} ></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 5: Energy Policy</p>
                        <p className="task-score">{taskScore5}/100</p>
                        <TaskCharacterization score={taskScore5} ></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 6:  Energy Management Team and Resources</p>
                        <p className="task-score">{taskScore6}/100</p>
                        <TaskCharacterization score={taskScore6}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 7: Risks to EnMS Success</p>
                        <p className="task-score">{taskScore7}/100</p>
                        <TaskCharacterization score={taskScore7}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 8: Energy Data Collection Plan</p>
                        <p className="task-score">{taskScore8}/100</p>
                        <TaskCharacterization score={taskScore8}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 9: Significant Energy Uses (SEU)</p>
                        <p className="task-score">{taskScore9}/100</p>
                        <TaskCharacterization score={taskScore9} ></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 10: Improvement Opportunitites</p>
                        <p className="task-score">{taskScore10}/100</p>
                        <TaskCharacterization score={taskScore10}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 11: Energy Performance Indicators and Energy Baselines</p>
                        <p className="task-score">{taskScore11}/100</p>
                        <TaskCharacterization score={taskScore11}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 12: Objectives and Targets</p>
                        <p className="task-score">{taskScore12}/100</p>
                        <TaskCharacterization score={taskScore12}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 13: Action Plans for Continual Improvement</p>
                        <p className="task-score">{taskScore13}/100</p>
                        <TaskCharacterization score={taskScore13}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 14: Competence and Training</p>
                        <p className="task-score">{taskScore14}/100</p>
                        <TaskCharacterization score={taskScore14}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 15: Awareness and Communication</p>
                        <p className="task-score">{taskScore15}/100</p>
                        <TaskCharacterization score={taskScore15}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 16: Documenting the EnMS</p>
                        <p className="task-score">{taskScore16}/100</p>
                        <TaskCharacterization score={taskScore16}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 17: Operational planning and control</p>
                        <p className="task-score">{taskScore17}/100</p>
                        <TaskCharacterization score={taskScore17}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 18: Energy Considerations in Design</p>
                        <p className="task-score">{taskScore18}/100</p>
                        <TaskCharacterization score={taskScore18}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 19: Energy Considerations in Procurement</p>
                        <p className="task-score">{taskScore19}/100</p>
                        <TaskCharacterization score={taskScore19}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 20: Monitoring and Measurement of the EnMS</p>
                        <p className="task-score">{taskScore20}/100</p>
                        <TaskCharacterization score={taskScore20}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 21: Monitoring and Measurement of Energy Performance Improvement</p>
                        <p className="task-score">{taskScore21}/100</p>
                        <TaskCharacterization score={taskScore21}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 22: Internal Audit</p>
                        <p className="task-score">{taskScore22}/100</p>
                        <TaskCharacterization score={taskScore22}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 23: Management Review</p>
                        <p className="task-score">{taskScore23}/100</p>
                        <TaskCharacterization score={taskScore23}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 24: NonConformity and Corrective Actions</p>
                        <p className="task-score">{taskScore24}/100</p>
                        <TaskCharacterization score={taskScore24}></TaskCharacterization>
                    </div>
                    <div className="task-wrapper">
                        <p className="task-name">Task 25: Continual Improvement</p>
                        <p className="task-score">{taskScore25}/100</p>
                        <TaskCharacterization score={taskScore25}></TaskCharacterization>
                    </div>
                </div>
            </div>
            <button className="get-results-button" onClick={saveToPdf} data-html2canvas-ignore="true">
                <p className="get-results-button-text">Save Results</p>
            </button>
        </div>
    );
  }