import { useEffect, useState } from "react";
import RadionButtons from '../RadioButtons/RadioButtons';
import Scores from '../Scores/Scores';
import "./Improvement.css"

export default function Improvement({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    setSectionScore,
    setTaskScore1, setTaskScore2,
    subTask1, setSubTask1, subTask2, setSubTask2, subTask3, setSubTask3, subTask4, setSubTask4, 
    subTask5, setSubTask5, subTask6, setSubTask6,
   }) {

    const [subTaksValidity1, setSubTaskValidity1] = useState(true)
    const [subTaksValidity2, setSubTaskValidity2] = useState(true)
    const [subTaksValidity3, setSubTaskValidity3] = useState(true)
    const [subTaksValidity4, setSubTaskValidity4] = useState(true)
    const [subTaksValidity5, setSubTaskValidity5] = useState(true)
    const [subTaksValidity6, setSubTaskValidity6] = useState(true)

    const checkInputValidity = () => {
        
        let notificationText = 'Please fill in sub-task(s) '
        let validity = true

        if(subTask1 === null) {
            setSubTaskValidity1(false)
            notificationText += '24.1, '
            validity = false
        }
        if(subTask2  === null) {
            setSubTaskValidity2(false)
            notificationText += '24.2, '
            validity = false
        }
        if(subTask3 === null) {
            setSubTaskValidity3(false)
            notificationText += '24.3, '
            validity = false
        }
        if(subTask4 === null) {
            setSubTaskValidity4(false)
            notificationText += '25.1, '
            validity = false
        }
        if(subTask5 === null) {
            setSubTaskValidity5(false)
            notificationText += '25.2, '
            validity = false
        }
        if(subTask6 === null) {
            setSubTaskValidity6(false)
            notificationText += '25.3, '
            validity = false
        }
 
        setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            index === 7 ? validity : inputValidityNew
        ));

        if(!validity) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
        } else {
            calculateSectionScore()
            calculateTasksScore()
        }

    }

    const calculateSectionScore = () => {
        setSectionScore(Math.round((subTask1 + subTask2 + subTask3 + subTask4 + subTask5 + subTask6) / 6 * 20))
    }

    const calculateTasksScore = () => {
        setTaskScore1(Math.round((subTask1 + subTask2 + subTask3) / 3 * 20))
        setTaskScore2(Math.round((subTask4 + subTask5 + subTask6) / 3 * 20))
    }

    useEffect(() => {
        if(inputCheck[7]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper">
            <div className='heading-wrapper'>
                <h2 className="step-heading">Improvement</h2>
                <p className="step-description">Improvement category focuses on the continuous enhancement of energy performance and the effectiveness of the Energy Management System (EnMS). It requires organizations to systematically identify opportunities for improvement, implement corrective and preventive actions, and ensure that energy performance objectives are consistently met and optimized over time.</p>
                <Scores></Scores>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 24: NonConformity and Corrective Actions</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 24.1:</span>Do you develop and implement a process for taking corrective action at your organization?</div>
                            <RadionButtons value={subTask1} setValue={setSubTask1} valid={subTaksValidity1} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 24.2:</span>Do you define roles, responsibilities, and authorities for the various steps in the corrective action process?</div>
                            <RadionButtons value={subTask2} setValue={setSubTask2} valid={subTaksValidity2} setValid={setSubTaskValidity2}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 24.3:</span>Do you document the results of the various stages of the corrective action process?</div>
                            <RadionButtons value={subTask3} setValue={setSubTask3} valid={subTaksValidity3} setValid={setSubTaskValidity3}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 25: Continual Improvement</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 25.1:</span>Do you ensure that frequent processes for assessing and updating certain sections of the energy management system (EnMS) are in place, as well as that necessary choices on "how," "when," and "who" are made and implemented?</div>
                            <RadionButtons value={subTask4} setValue={setSubTask4} valid={subTaksValidity4} setValid={setSubTaskValidity4}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 25.2:</span>Do you review processes for integrating EnMS requirements into the organization’s business operations and practices?</div>
                            <RadionButtons value={subTask5} setValue={setSubTask5} valid={subTaksValidity5} setValid={setSubTaskValidity5}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 25.3:</span>Do you ensure you have processes in place to continually improve the EnMS and energy performance?</div>
                            <RadionButtons value={subTask6} setValue={setSubTask6} valid={subTaksValidity6} setValid={setSubTaskValidity6}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }