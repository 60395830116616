import { useState } from 'react';
import Header from '../Header/Header';
import './Popup.css'

export default function Popup() {

    const [showPopup, setShowPopup] = useState(true)

    const startAssessment = () => {
        setShowPopup(false)
    }

    return (
        <>
        {showPopup &&
            <div className='popup'>
                <div className="popup-window">
                    <Header></Header>
                    <div className='popup-content'>
                        <p className='popup-paragraph'>Energy management is an integral part of modern industrial operations and 
                            implementing an efficient energy management system (EnMS) can result in 
                            increased energy efficiency, cost savings, and environmental 
                            sustainability. ISO 50001 is a worldwide recognized energy management 
                            standard that provides a methodical framework for enterprises to design, 
                            implement, maintain, and enhance their EnMS while becoming the most 
                            important worldwide standard for energy management systems since its 
                            publication in 2011. Since energy-intensive industrial companies must 
                            decide whether to implement an energy management system or an energy 
                            audit, the Audit2Measure project developed a questionnaire to assist 
                            industrial companies in assessing the maturity level of their energy 
                            management practices, with a particular emphasis on ISO 50001 compliance. 
                        </p> 
                        <p className='popup-paragraph'>
                            This tool is intended to help industrial organizations identify their 
                            current degree of energy management maturity, build or improve existing 
                            energy management practices, and understand how such practices may have 
                            evolved over time. The assessment can be undertaken by any organization 
                            irrespective of prior energy management practices knowledge, size of the 
                            organization or existing energy management system certification.  
                            The questionnaire addresses seven categories of the ISO standard 
                            (organizational context, leadership, planning, support, operations, 
                            performance evaluation, and improvement) in depth, including energy 
                            performance target setting, employee engagement and training, monitoring 
                            and tracking, and stakeholder communication.
                        </p>
                    </div>
                    <button className="button filled-button start-button" onClick={startAssessment}>Start The Assessment</button>
                </div>
            </div>
        }
        </>
    );
  }