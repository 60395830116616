import Form from './Pages/Form/Form';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import { useState } from 'react';
import Results from './Pages/Results/Results';
import Popup from './Components/Popup/Popup';

function App() {

  const [page, setPage] = useState('form')

  const [personPosition, setPersonPosition] = useState('');
  const [companyCountry, setCompanyCountry] = useState('');
  const [industrialSector, setIndustrialSector] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [numberOfSites, setNumberOfSites] = useState('');
  const [energyConsumption, setEnergyConsumption] = useState('');
  const [assessmentLevel, setAssessmentLevel] = useState('');
  const [pastAudits, setPastAudits] = useState('');
  const [mandatoryAudits, setMandatoryAudits] = useState('');
  const [energyManagementSystemBool, setEnergyManagementSystemBool] = useState('');
  const [energyManagementSystem, setEnergyManagementSystem] = useState('');

  const [organizationalContextScore, setOrganizationalContextScore] = useState()
  const [leadershipScore, setLeadershipScore] = useState()
  const [planningScore, setPlanningScore] = useState()
  const [supportScore, setSupportScore] = useState()
  const [operationsScore, setOperationsScore] = useState()
  const [performanceEvaluationScore, setPerformanceEvaluationScore] = useState()
  const [improvementScore, setImprovementScore] = useState()

  const [taskScore1, setTaskScore1] = useState()
  const [taskScore2, setTaskScore2] = useState()
  const [taskScore3, setTaskScore3] = useState()
  const [taskScore4, setTaskScore4] = useState()
  const [taskScore5, setTaskScore5] = useState()
  const [taskScore6, setTaskScore6] = useState()
  const [taskScore7, setTaskScore7] = useState()
  const [taskScore8, setTaskScore8] = useState()
  const [taskScore9, setTaskScore9] = useState()
  const [taskScore10, setTaskScore10] = useState()
  const [taskScore11, setTaskScore11] = useState()
  const [taskScore12, setTaskScore12] = useState()
  const [taskScore13, setTaskScore13] = useState()
  const [taskScore14, setTaskScore14] = useState()
  const [taskScore15, setTaskScore15] = useState()
  const [taskScore16, setTaskScore16] = useState()
  const [taskScore17, setTaskScore17] = useState()
  const [taskScore18, setTaskScore18] = useState()
  const [taskScore19, setTaskScore19] = useState()
  const [taskScore20, setTaskScore20] = useState()
  const [taskScore21, setTaskScore21] = useState()
  const [taskScore22, setTaskScore22] = useState()
  const [taskScore23, setTaskScore23] = useState()
  const [taskScore24, setTaskScore24] = useState()
  const [taskScore25, setTaskScore25] = useState()

  return (
    <div className="App">
      <Header></Header>
      <Popup></Popup>
      {page === 'form' &&
        <Form 
          setPage={setPage}
          personPosition={personPosition} setPersonPosition={setPersonPosition} 
          companyCountry={companyCountry} setCompanyCountry={setCompanyCountry} 
          industrialSector={industrialSector} setIndustrialSector={setIndustrialSector} 
          companySize={companySize} setCompanySize={setCompanySize} 
          numberOfSites={numberOfSites} setNumberOfSites={setNumberOfSites} 
          energyConsumption={energyConsumption} setEnergyConsumption={setEnergyConsumption} 
          assessmentLevel={assessmentLevel} setAssessmentLevel={setAssessmentLevel} 
          pastAudits={pastAudits} setPastAudits={setPastAudits} 
          mandatoryAudits={mandatoryAudits} setMandatoryAudits={setMandatoryAudits} 
          energyManagementSystemBool={energyManagementSystemBool} setEnergyManagementSystemBool={setEnergyManagementSystemBool} 
          energyManagementSystem={energyManagementSystem} setEnergyManagementSystem={setEnergyManagementSystem}
          organizationalContextScore={organizationalContextScore} setOrganizationalContextScore={setOrganizationalContextScore}
          leadershipScore={leadershipScore} setLeadershipScore={setLeadershipScore}
          planningScore={planningScore} setPlanningScore={setPlanningScore}
          supportScore={supportScore} setSupportScore={setSupportScore}
          operationsScore={operationsScore} setOperationsScore={setOperationsScore}
          performanceEvaluationScore={performanceEvaluationScore} setPerformanceEvaluationScore={setPerformanceEvaluationScore}
          improvementScore={improvementScore} setImprovementScore={setImprovementScore}
          taskScore1={taskScore1} setTaskScore1={setTaskScore1}
          taskScore2={taskScore2} setTaskScore2={setTaskScore2}
          taskScore3={taskScore3} setTaskScore3={setTaskScore3}
          taskScore4={taskScore4} setTaskScore4={setTaskScore4}
          taskScore5={taskScore5} setTaskScore5={setTaskScore5}
          taskScore6={taskScore6} setTaskScore6={setTaskScore6}
          taskScore7={taskScore7} setTaskScore7={setTaskScore7}
          taskScore8={taskScore8} setTaskScore8={setTaskScore8}
          taskScore9={taskScore9} setTaskScore9={setTaskScore9}
          taskScore10={taskScore10} setTaskScore10={setTaskScore10}
          taskScore11={taskScore11} setTaskScore11={setTaskScore11}
          taskScore12={taskScore12} setTaskScore12={setTaskScore12}
          taskScore13={taskScore13} setTaskScore13={setTaskScore13}
          taskScore14={taskScore14} setTaskScore14={setTaskScore14}
          taskScore15={taskScore15} setTaskScore15={setTaskScore15}
          taskScore16={taskScore16} setTaskScore16={setTaskScore16}
          taskScore17={taskScore17} setTaskScore17={setTaskScore17}
          taskScore18={taskScore18} setTaskScore18={setTaskScore18}
          taskScore19={taskScore19} setTaskScore19={setTaskScore19}
          taskScore20={taskScore20} setTaskScore20={setTaskScore20}
          taskScore21={taskScore21} setTaskScore21={setTaskScore21}
          taskScore22={taskScore22} setTaskScore22={setTaskScore22}
          taskScore23={taskScore23} setTaskScore23={setTaskScore23}
          taskScore24={taskScore24} setTaskScore24={setTaskScore24}
          taskScore25={taskScore25} setTaskScore25={setTaskScore25}
        ></Form>
      }
      {page === 'results' &&
        <Results
          personPosition={personPosition}
          companyCountry={companyCountry}
          industrialSector={industrialSector} 
          companySize={companySize}
          numberOfSites={numberOfSites}
          energyConsumption={energyConsumption}
          assessmentLevel={assessmentLevel}
          pastAudits={pastAudits}
          mandatoryAudits={mandatoryAudits}
          energyManagementSystemBool={energyManagementSystemBool}
          energyManagementSystem={energyManagementSystem}
          organizationalContextScore={organizationalContextScore}
          leadershipScore={leadershipScore}
          planningScore={planningScore}
          supportScore={supportScore}
          operationsScore={operationsScore}
          performanceEvaluationScore={performanceEvaluationScore}
          improvementScore={improvementScore}
          taskScore1={taskScore1}
          taskScore2={taskScore2}
          taskScore3={taskScore3}
          taskScore4={taskScore4}
          taskScore5={taskScore5}
          taskScore6={taskScore6}
          taskScore7={taskScore7}
          taskScore8={taskScore8}
          taskScore9={taskScore9}
          taskScore10={taskScore10}
          taskScore11={taskScore11}
          taskScore12={taskScore12}
          taskScore13={taskScore13}
          taskScore14={taskScore14}
          taskScore15={taskScore15}
          taskScore16={taskScore16}
          taskScore17={taskScore17}
          taskScore18={taskScore18}
          taskScore19={taskScore19}
          taskScore20={taskScore20}
          taskScore21={taskScore21}
          taskScore22={taskScore22}
          taskScore23={taskScore23}
          taskScore24={taskScore24}
          taskScore25={taskScore25}
        ></Results>
      }
      <Footer></Footer>
    </div>
  );
}

export default App;
