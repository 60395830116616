import { useEffect, useState } from "react";
import RadionButtons from '../RadioButtons/RadioButtons';
import Scores from '../Scores/Scores';
import "./Support.css";

export default function Support({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    setSectionScore,
    setTaskScore1, setTaskScore2, setTaskScore3,
    subTask1, setSubTask1, subTask2, setSubTask2, subTask3, setSubTask3, subTask4, setSubTask4, 
    subTask5, setSubTask5, subTask6, setSubTask6, subTask7, setSubTask7, subTask8, setSubTask8, 
    subTask9, setSubTask9, subTask10, setSubTask10, subTask11, setSubTask11, subTask12, setSubTask12, 
    subTask13, setSubTask13
   }) {

    const [subTaksValidity1, setSubTaskValidity1] = useState(true)
    const [subTaksValidity2, setSubTaskValidity2] = useState(true)
    const [subTaksValidity3, setSubTaskValidity3] = useState(true)
    const [subTaksValidity4, setSubTaskValidity4] = useState(true)
    const [subTaksValidity5, setSubTaskValidity5] = useState(true)
    const [subTaksValidity6, setSubTaskValidity6] = useState(true)
    const [subTaksValidity7, setSubTaskValidity7] = useState(true)
    const [subTaksValidity8, setSubTaskValidity8] = useState(true)
    const [subTaksValidity9, setSubTaskValidity9] = useState(true)
    const [subTaksValidity10, setSubTaskValidity10] = useState(true)
    const [subTaksValidity11, setSubTaskValidity11] = useState(true)
    const [subTaksValidity12, setSubTaskValidity12] = useState(true)
    const [subTaksValidity13, setSubTaskValidity13] = useState(true)

    const checkInputValidity = () => {
        
        let notificationText = 'Please fill in sub-task(s) '
        let validity = true

        if(subTask1 === null) {
            setSubTaskValidity1(false)
            notificationText += '14.1, '
            validity = false
        }
        if(subTask2  === null) {
            setSubTaskValidity2(false)
            notificationText += '14.2, '
            validity = false
        }
        if(subTask3 === null) {
            setSubTaskValidity3(false)
            notificationText += '14.3, '
            validity = false
        }
        if(subTask4 === null) {
            setSubTaskValidity4(false)
            notificationText += '15.1, '
            validity = false
        }
        if(subTask5 === null) {
            setSubTaskValidity5(false)
            notificationText += '15.2, '
            validity = false
        }
        if(subTask6 === null) {
            setSubTaskValidity6(false)
            notificationText += '15.3, '
            validity = false
        }
        if(subTask7 === null) {
            setSubTaskValidity7(false)
            notificationText += '15.4, '
            validity = false
        }
        if(subTask8 === null) {
            setSubTaskValidity8(false)
            notificationText += '15.5, '
            validity = false
        }
        if(subTask9  === null) {
            setSubTaskValidity9(false)
            notificationText += '15.6, '
            validity = false
        }
        if(subTask10 === null) {
            setSubTaskValidity10(false)
            notificationText += '16.1, '
            validity = false
        }
        if(subTask11 === null) {
            setSubTaskValidity11(false)
            notificationText += '16.2, '
            validity = false
        }
        if(subTask12 === null) {
            setSubTaskValidity12(false)
            notificationText += '16.3, '
            validity = false
        }
        if(subTask13 === null) {
            setSubTaskValidity13(false)
            notificationText += '16.4, '
            validity = false
        }

        setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            index === 4 ? validity : inputValidityNew
        ));

        if(!validity) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
        } else {
            calculateSectionScore()
            calculateTasksScore()
        }

    }

    const calculateSectionScore = () => {
        setSectionScore(Math.round((subTask1 + subTask2 + subTask3 + subTask4 + subTask5 + subTask6 + subTask7 + subTask8 + subTask9 + subTask10 + subTask11 + subTask12 + subTask13) / 13 * 20))
    }

    const calculateTasksScore = () => {
        setTaskScore1(Math.round((subTask1 + subTask2 + subTask3) / 3 * 20))
        setTaskScore2(Math.round((subTask4 + subTask5 + subTask6 + subTask7 + subTask8 + subTask9) / 6 * 20))
        setTaskScore3(Math.round((subTask10 + subTask11 + subTask12 + subTask13) / 4 * 20))
    }

    useEffect(() => {
        if(inputCheck[4]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper">
            <div className='heading-wrapper'>
                <h2 className="step-heading">Support</h2>
                <p className="step-description">Support category outlines the resources, tools, and processes needed to implement, maintain, and improve the Energy Management System (EnMS). It ensures that all necessary elements are in place to effectively execute energy management activities and achieve the organization’s energy objectives. Support includes people, infrastructure, awareness, competence, and communication.</p>
                <Scores></Scores>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 14: Competence and Training</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 14.1:</span>Do you determine necessary competencies for internal and external personnel and evaluate their current competencies?</div>
                            <RadionButtons value={subTask1} setValue={setSubTask1} valid={subTaksValidity1} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 14.2:</span>Do you identify any gaps in the competencies of personnel whose work affects energy performance and the energy management system (EnMS), and conduct training to address competency gaps?</div>
                            <RadionButtons value={subTask2} setValue={setSubTask2} valid={subTaksValidity2} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 14.3:</span>Do you provide verification of people's competences such as proof of vocational training, trainings, skills and experiences?</div>
                            <RadionButtons value={subTask3} setValue={setSubTask3} valid={subTaksValidity3} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 15: Awareness and Communication</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 15.1:</span>Do you develop and deliver an initial energy management system (EnMS) communication from top management that includes the importance of energy management and the organization’s energy policy?</div>
                            <RadionButtons value={subTask4} setValue={setSubTask4} valid={subTaksValidity4} setValid={setSubTaskValidity4}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 15.2:</span>Do you allow anyone working for your organisation to make comments on how to improve the EnMS and energy performance?</div>
                            <RadionButtons value={subTask5} setValue={setSubTask5} valid={subTaksValidity5} setValid={setSubTaskValidity5}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 15.3:</span>Do you plan and implement awareness training?</div>
                            <RadionButtons value={subTask6} setValue={setSubTask6} valid={subTaksValidity6} setValid={setSubTaskValidity6}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 15.4:</span>Has your organization's energy policy  been disclosed to all employees and other relevant parties?</div>
                            <RadionButtons value={subTask7} setValue={setSubTask7} valid={subTaksValidity7} setValid={setSubTaskValidity7}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 15.5:</span>Do you know if all employees and other relevant persons are aware of their contribution to the effectiveness of the EnMS including the achievement of objectives and energy targets and benefits of improved energy performance?</div>
                            <RadionButtons value={subTask8} setValue={setSubTask8} valid={subTaksValidity8} setValid={setSubTaskValidity8}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 15.6:</span>Do you know if all employees and other relevant persons are aware of the implications of not conforming with the EnMS requirements?</div>
                            <RadionButtons value={subTask9} setValue={setSubTask9} valid={subTaksValidity9} setValid={setSubTaskValidity9}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 16: Documenting the EnMS</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 16.1:</span>Do you ensure that your EnMS includes the documented information?</div>
                            <RadionButtons value={subTask10} setValue={setSubTask10} valid={subTaksValidity10} setValid={setSubTaskValidity10}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 16.2:</span>Do you determine what additional documented information is required to assure the effectiveness of the EnMS and to demonstrate an improvement in energy performance?</div>
                            <RadionButtons value={subTask11} setValue={setSubTask11} valid={subTaksValidity11} setValid={setSubTaskValidity11}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 16.3:</span>Do you list your EnMS documents, assign document proprietors and approvers, and define the pertinent document controls?</div>
                            <RadionButtons value={subTask12} setValue={setSubTask12} valid={subTaksValidity12} setValid={setSubTaskValidity12}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 16.4:</span>Do you ensure that the documented information is available when needed and adequately protected?</div>
                            <RadionButtons value={subTask13} setValue={setSubTask13} valid={subTaksValidity13} setValid={setSubTaskValidity13}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }