import "./Scores.css"

export default function Scores() {
    return (
        <>
            <p className='scores-heading'>Level of Task Completion:</p>
            <div className='scores-wrapper'>
                <div className='score'>
                    <div className='score-index'>0</div>
                    <div className='score-text'>We are uncertain what this sub-task means.</div>
                </div>
                <div className='score'>
                    <div className='score-index'>1</div>
                    <div className='score-text'>We understand what is required for this sub-task but have not yet started acting on it.</div>
                </div>
                <div className='score'>
                    <div className='score-index'>2</div>
                    <div className='score-text'>We are taking some action on this sub-task but not yet to the extent of the description provided in the above two choices.</div>
                </div>
                <div className='score'>
                    <div className='score-index'>3</div>
                    <div className='score-text'>Our operations for this sub-task are currently functional, but we believe that they could benefit from further improvement in the future.</div>
                </div>
                <div className='score'>
                    <div className='score-index'>4</div>
                    <div className='score-text'>We have taken systematic and well-planned actions in this sub-task, and these actions have been tested over time.</div>
                </div>
            </div>
        </>
    );
  }